global.iframeError; // lưu lỗi load iframe

export function error() {
    $("div.content > div#loading").hide();
}

/* sử dụng dùng chung cho tất cả các file js */

// tải 1 trang bắt kỳ bằng ajax vào 1 thẻ cần chứa nội dung, version 1
// - path: đường dẫn trang cần tải
// - content: id của thẻ cần chứa nội dung tải
export function tai_trang(path, content) {
    jQuery.ajax({
        type: "POST",
        url: path,
        dataType: "html",
        beforeSend: function () {
            $("#loading").show();
        },
        success: function (ser_res) {
            $("#loading").hide();
            content.hide().html(ser_res).fadeIn('slow');

        },
        error: function (ex) {
            alert("Error Server!!!" + ex);
        }
    });
}

export function tai_trang_get(path, content) {
    jQuery.ajax({
        type: "GET",
        url: path,
        dataType: "html",
        beforeSend: function () {
            $("#loading").show();
        },
        success: function (ser_res) {
            $("#loading").hide();
            content.hide().html(ser_res).fadeIn('slow');

        },
        error: function (ex) {
            alert("Error Server!!!" + ex);
        }
    });
}

// tải 1 trang bắt kỳ bằng ajax vào 1 thẻ cần chứa nội dung, sử dụng trên dialog version 2
// - path: đường dẫn trang cần tải
// - content: id của thẻ cần chứa nội dung tải
export function tai_trang_v2(path, content) {
    jQuery.ajax({
        type: "POST",
        url: path,
        dataType: "html",
        beforeSend: function () {
            $("#dialog_wrapper .footer .loading").show();
        },
        success: function (ser_res) {
            $("#dialog_wrapper .footer .loading").hide();
            content.hide().html(ser_res).fadeIn('slow');
        },
        error: function (ex) {
            alert("Error Server!!!" + ex);
        }
    });
}

// gửi dữ liệu đến server - và server trả dữ liệu json về
// - path: đường dẫn server cần gửi đến
// - parameter: tham số json cần truyền vào
// - nhan_du_lieu: hàm callback để định nghĩa lại, có tham số là giá trị server trả về
export function gui_du_lieu(url, nhan_du_lieu, options) {
    options = jQuery.extend({
        method: "POST",
        data_type: "json",
        data: {},
        txt_error: "Lỗi Khi Lấy Dữ liệu!"
    }, options || {});

    jQuery.ajax({
        type: options.method,
        url: url,
        data: options.data,
        contentType: 'application/json',
        dataType: options.data_type,
        beforeSend: function () {
            $("#loading").fadeIn();
        },
        success: function (ser_res) {
            $("#loading").fadeOut();
            nhan_du_lieu(ser_res);
        },
        error: function (ex) {
            parent.top.toastr["error"](options.txt_error);
            if (options.function_error && typeof(options.function_error) === "function")
                options.function_error();
            console.log("Error Server!!!" + ex.message);
        }
    });
}

// simple implementation based on $.extend() from jQuery
export function merge() {
    var obj, name, copy,
        target = arguments[0] || {},
        i = 1,
        length = arguments.length;

    for (; i < length; i++) {
        if ((obj = arguments[i]) != null) {
            for (name in obj) {
                copy = obj[name];

                if (target === copy) {
                    continue;
                }
                else if (copy !== undefined) {
                    target[name] = copy;
                }
            }
        }
    }
    return target;
}

// chuyển giá trị undefined, null thành chuỗi rỗng
// - str: giá trị cần chuyển
export function chuyen_doi_gia_tri(str) {
    if (str == null || str == "undefined") {
        return "";
    } else {
        return str;
    }
}

// show form gợi ý
// - tieude: là tiêu đề của form
// - content: là id cần hiển thị form gợi ý
export function form_goi_y(tieude, content) {
    // làm sach thẻ wrapper
    $("#wrapper").remove();
    // định nghĩa biến form gợi ý
    var form = "<div id=\"wrapper\" style=\"position:absolute\">";
    form += "<link rel=\"stylesheet\" href=\"/assets/cyber/lib/smartsearch.css\">";
    form += "<div id=\"smart-search\" style=\"height: 200px; overflow: auto;\">";
    form += "<table cellspacing=\"0\" cellpadding=\"1\" border=\"0\" width=\"600px\" height=\"auto\">";

    form += "<tr>";
    // duyệt từng phần tử thêm
    for (var i = 0; i < tieude.split("~").length; i++) {
        form += "<th style=\"padding: 0 5px; vertical-align: middle\"><span>" + tieude.split("~")[i] + "</span></th>";
    }
    form += "</tr>";
    form += "<tbody id=\"tbodyfilldata\">";
    form += "</tbody>";
    form += "</table>";
    form += "</div>";
    form += "<div style=\"text-align: right; cursor :pointer; background-color: #B0B0B0\"><span class=\"clear\">[&nbsp&nbsp]</span> | <span class=\"close\"><img src=\"/assets/cyber/icon/close.png\" alt=\"close\"/></span></div>";
    form += "</div>";

    // xác định vị trí top - left của element cần hiển thị trên form goi y
    var x = $(content).offset().left;
    var y = $(content).offset().top + $(content).height();

    // thêm form suggest vào body trang gọi hàm showsuggest
    $("body").append(form);
    // thiet lập vị trí hiển thị form gợi ý
    $("#wrapper").css({display: "block", left: x, top: y});

    // sự kiện click close
    $('#wrapper .close').click(function () {
        // ẩn
        $("#wrapper").hide();
        // sau đó xóa thẻ wrapper
        $("#wrapper").remove();
    });

    // sự kiện click xóa trắng
    $('#wrapper .clear').click(function () {
        // xóa trắng dữ liệu trong thể input (key và value)
        $(content).val('');
        $(content).attr('key', '');

        // ẩn
        $("#wrapper").hide();
        // sau đó xóa thẻ wrapper
        $("#wrapper").remove();
    });
}

// điền dữ liệu dạng table (bảng), nghĩa là các dòng điều giống nhau chỉ khác dữ liệu đổ vào
// - id: là id của thẻ input
// - keyvalue: nội dung hiển thị, và nội dung khóa
// - cols: tên các cột vd: id~ten~diachi
// - datajson: dữ liệu json
// - inneroptionfunc: hàm tùy chọn bên trong sự kiện dbclick dòng trên form gợi ý
export function dien_du_lieu_dang_table(id, keyvalue, cols, datajson, inneroptionfunc) {
    // định nghĩa biến chứa nội dung tìm được
    var form = "";
    // định nghĩa biến chứa dữ liệu json
    var parsedatajson;
    try {
        parsedatajson = jQuery.parseJSON(datajson);
    } catch (ex) {
        parsedatajson = datajson;
    }
    try {
        // duyệt từng phần tử json
        jQuery.each(parsedatajson, function (i, item) {
            // khi hiển thị form gợi ý lên, mặc định sẽ "active" dòng đầu tiên
            if (i == 0) {
                form += "<tr class=\"active\">";
            } else {
                form += "<tr>";
            }
            // duyệt từng phần tử cols
            for (var j = 0; j < cols.split("~").length; j++) {
                // kiểm tra xem cột nào có ký hiệu [] thì ẩn đi, ngược lại thì hiển thị
                // vd: tentt~[vung]~ma
                if (cols.split("~")[j].match(/^\[/) != null) {
                    form += "<td style=\"display: none\">" + chuyen_doi_gia_tri(item[cols.split("~")[j].substr(1, cols.split("~")[j].lastIndexOf("]") - 1)]) + "</td>";
                } else {
                    form += "<td style=\"padding: 0 5px; vertical-align: middle\">" + chuyen_doi_gia_tri(item[cols.split("~")[j]]) + "</td>";
                }
            }
            form += "</tr>";
        });
    } catch (ex) {
        // trường hợp lỗi xảy ra thì ko hiển thị
        form += "<tr></tr>"
    }
    // thêm vào tbody của table trong form gợi ý
    $("#tbodyfilldata").html(form);

    // sự kiện khi click 1 dòng
    $('#wrapper #smart-search table tr').click(function () {
        $("#wrapper #smart-search table .active").removeClass("active");
        $(this).addClass('active');
    });

    // sự kiện nhấn dbclick tr
    $('#wrapper #smart-search table tr').dblclick(function () {
        // gán dữ liệu vào
        var key = $(this).children().eq(parseInt(keyvalue.split("~")[0])).text();
        var value = $(this).children().eq(parseInt(keyvalue.split("~")[1])).text();
        // gán thuộc tính key
        $(id).attr("key", key);
        // gán thuộc tính value
        $(id).val(value);
        // hàm tùy biến
        try {
            inneroptionfunc(this);
        } catch (ex) {
            console.log(ex);
        }
        // gọi sự kiện close
        $("#wrapper .close").trigger('click');
    });
}

// cắt chuỗi theo dạng split, trả về chuỗi cần lấy
//  - str: chuỗi dữ liệu
//  - index: vị trí cần lấy
export function cat_chuoi_split(str, split, index) {
    // lấy chuỗi cần phân cách
    var temp_split = str.split(split);
    // khai báo biến trả về
    var temp_str = '';
    // duyệt từng phần tửng mảng
    for (var i = 0; i < temp_split.length; i++) {
        // tìm số index cần tìm
        if (i == index) {
            // gán chuỗi cần tìm và thoát vòng lặp
            temp_str = temp_split[i].toString();
            break;
        }
    }
    // trả về giá trị
    return temp_str;
}

// hiện trang web, sử dụng cho flexigrid
//  - id: tên id (nội dung) cần hiển thị
export function hien_thi_web(id) {
    // hiển thị từ từ
    id.fadeIn('slow');
    // thiết lập z-index cho class gBlock trong flexigrid
    $(".gBlock").css('z-index', '-1');
}

// dịch đa ngôn ngữ
//  - index: chỉ số của ngôn ngữ cần dịch
export function translation(index) {
    return lang[index];
}

// lấy chuỗi theo dạng key=value
//  - str: chuỗi cần truyền vào
//  - name: tên key cần lấy
export function lay_chuoi_dang_key_value(str, name, dauphancach) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?" + dauphancach + "]" + name + "=([^" + dauphancach + "]*)"),
        results = regex.exec(dauphancach + str);
    return results == null ? "" : results[1].replace(/\+/g, " ");
}

export function reset_pos_from_child() {
    var width = parent.top.$("#content > div:last-child").width() - 1;
    var height = parent.top.$("#content > div:last-child").height() - 1;
    var left = parent.top.$("#content").width() - width;
    var top = parent.top.$("body").height() - height - 2;
    // gán giá trị w, h cho dialog
    parent.top.$("#dialog_wrapper").css({
        width: width,
        height: height,
        position: 'absolute',
        left: left + "px",
        top: top + "px"
    });
}

/**
 * @param {numberic} val giá trị tổng khi hiển thị
 * @param {numberic} precision là độ chính xác phần thập phân
 */
export function show_sum_grid(val, precision) {
    return (val == undefined || val.toString()) == "NaN" ? 0 : parseFloat(val).formatMoney(precision);
}

Number.prototype.formatMoney = function (c, d, t) {
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

/**
 * func same sleep in ruby
 * @param {numberic} time delay before exec code callback
 */
export function delay() {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
};
// var delay = (function () {
//     var timer = 0;
//     return function (callback, ms) {
//         clearTimeout(timer);
//         timer = setTimeout(callback, ms);
//     };
// })();

//jQuery(function($) {
//    $('[data-remote]').on('ajax:before', function(event, data) {
//        debugger;
//        return true;
//    });
//    $('[data-remote]').on('ajax:beforeSend', function(event, data) {
//        debugger;
//        return true;
//    });
//    //$('[data-remote]')
//    //    .data('type', 'js')
//    //    .on('ajax:success', function(event, data) {
//    //        debugger;
//    //        var $this = $(this);
//    //        $($this.data('replace')).html(data);
//    //        $this.trigger('ajax:replaced');
//    //        return true;
//    //    });
//});
//
//jQuery(document).delegate('[data-remote]', 'click', function(e) {
//    debugger;
//    $.rails.handleRemote($(this));
//    e.preventDefault();
//});
