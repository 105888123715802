import React from "react"
import LikelihoodsList from './list'
import { Form, Col, Row, Button, Table, Tabs, Tab } from 'react-bootstrap'
import LikelihoodsForm from "./form"
import { GetLikelihoods, DeleteLikelihoods } from '../../api/likelihoods'
import { toast } from 'react-toastify'
class Likelihoods extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      activeTab: "list",
      likelihoods: [],
      editing: {}
    }
  }
  componentDidMount(){
    this.loadLikelihoods()
  }
  handleChangeTab = async (tab) => {
    return this.setState({ activeTab: tab})
  }
  loadLikelihoods = async () => {
    let res = await GetLikelihoods()
    if(res){
      this.setState({likelihoods: res})
    }
  }
  onSubmitSuccess = async () => {
      this.setState({activeTab: "list"})
      return this.loadLikelihoods()
  }
  
  onEdit = (item) => {
    this.setState({editing: item, activeTab: "create"})
  }
  onCancel = () => {
    this.setState({activeTab: "list", editing: {}})
  }

  onDelete = async (id) => {
    let res = await DeleteLikelihoods(id)
    toast.success(`Likelihoods #${id} was deleted successfully`)
    this.loadLikelihoods()
  }
  render(){
    return(
      <React.Fragment>
      <Row>
        <Col md={12}>
        <Tab.Container>
          <Tabs activeKey={this.state.activeTab} className="custom-tab-content" onSelect={k => this.handleChangeTab(k)}>
            <Tab eventKey={"list"} title={"List likelihoods"}>
              <LikelihoodsList onDelete={this.onDelete} onEdit={this.onEdit} likelihoods={this.state.likelihoods} />
            </Tab>
            <Tab eventKey={"create"} title={"Create likelihoods"}>
              <LikelihoodsForm editItem={this.state.editing} onCancel={this.onCancel} onSuccess={this.onSubmitSuccess} />
            </Tab>
          </Tabs>
        </Tab.Container>
        </Col>
      </Row>
      </React.Fragment>
    )
  }
}

export default Likelihoods