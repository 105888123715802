import axios from 'axios'

// import * as Reducers from '../redux/reducers'

const API_ROOT = ""
const PREFIX = '/'

function getHeaders() {

  const token = document.querySelector('[name=csrf-token]').content

  return {
    'Content-Type': 'application/json',
    'X-CSRF-TOKEN':  token //account.access_token,
  }
}

axios.defaults.baseURL = API_ROOT + PREFIX
axios.defaults.headers = getHeaders()

export function get(path) {
  return handleResponse(axios.get(path + ".json"))
}

export function post(path, body) {
  return handleResponse(axios.post(path + ".json", JSON.stringify(body)))
}

export function put(path, body) {
  return handleResponse(axios.put(path + ".json", JSON.stringify(body)))
}

export function patch(path, body) {
  return handleResponse(axios.patch(path + ".json", JSON.stringify(body)))
}

export function deleted(path) {
  return handleResponse(axios.delete(path + ".json"))
}

async function handleResponse(promise) {
  try {
    const { data, status, statusText, headers, config, request } = await promise
    return data
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // error.response.data;
      // error.response.status;
      // error.response.headers;
      
      if (error.response.status == 401) {
        //error.response.data.message = "Phiên làm việc đã hết hạn hoặc không có quyền truy cập. Vui lòng đăng xuất và đăng nhập lại để tiếp tục"
        // Reducers.logout()
        // window.location.href = "/#/login"
        return Promise.reject({
          data: error.response.data,
          status: error.response.status,
          url: error.response.config.url
        })
      } 

      return Promise.reject({
        data: error.response.data,
        status: error.response.status,
        url: error.response.config.url
      })
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // error.request;
      return Promise.reject({
        data: error.request,
        status: 404,
        statusText: 'error request'
      })
    } else {
      // Something happened in setting up the request that triggered an Error
      // error.message;
      return Promise.reject({
        data: error.message,
        status: 404,
        statusText: 'Something bad happened'
      })
    }
  }
}
