import * as Api from '../api'
const SEVERITY_PATH = "/hrac/api/severities"

export const CreateSeverity = async (params) => {
  try {
    return await Api.post(SEVERITY_PATH, params)
  } catch(err){
    console.log(err)
    return null
  }
}


export const GetSeverity = async () => {
  try {
    return await Api.get(SEVERITY_PATH)
  } catch(err){
    console.log(err)
    return null
  }
}

export const UpdateSeverity = async (params) => {
  try {
    return await Api.put(SEVERITY_PATH + "/" + params.id, params)
  } catch(err){
    console.log(err)
    return null
  }
}


export const DeleteSeverity = async (id) => {
  try {
    return await Api.deleted(SEVERITY_PATH + "/" + id)
  } catch(err){
    console.log(err)
    return null
  }
}