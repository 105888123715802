import "../stylesheets/new_application"
//import '../application/javascripts/index.js'
import JQuery from 'jquery';
//window.$ = window.JQuery = JQuery; don't work when use `jquery-ujs` because don't have `= window.jQuery`
window.$ = window.JQuery = window.jQuery = JQuery;
import 'popper.js'
import "bootstrap"
import "lodash"
import "jquery-ui/ui/widgets/datepicker"
import "jquery-ui/ui/i18n/datepicker-vi"
import "jquery-ui/ui/effects/effect-highlight"
import "bootstrap-table/dist/bootstrap-table"
import "bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns"
import "bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control"

$(function() {
  //$('#table-five-s-processes').bootstrapTable();

  //Moment.JS Return Date Ranges
  function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = new Date(startDate);
    var stopDate = new Date(stopDate);
    const datePattern = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z$/;
    //const datePattern = /^(\d{2})-(\d{2})-(\d{4})$/;
    //const datePattern = /^(\d{2})-(\d{2})-(\d{4})$/;
    while (currentDate <= stopDate) {
      //dateArray.push(currentDate.format('YYYY-MM-DD'))
      const [, year, month, day, hour, minute, second ] = datePattern.exec(currentDate.toISOString());
      //const [year, month, day, ] = /^(\d{4})-(\d{2})-(\d{2})$/.exec('2021-12-31 10:15:00 UTC');
      //var datePush = new Date(`${month}, ${day} ${year}`);
      //console.log(`${day}-${month}-${year}`);
      dateArray.push(`${day}-${month}-${year}`);

      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return dateArray;
  }


  $('#ok').click(function() {
    var $table = $('#table-five-s-processes')
    var from = $("input[type=date][name=date1]" ).val();
    var to = $("input[type=date][name=date2]" ).val();
    $table.bootstrapTable('filterBy',{ date_report: getDates(from,to)})
  });
});
//import toastr from 'toastr'
//window.toastr = toastr;
require("@nathanvda/cocoon")
import Handlebars from 'handlebars'
window.Handlebars = Handlebars;
Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
  switch (operator) {
    case '==':
      return (v1 == v2) ? options.fn(this) : options.inverse(this);
    case '===':
      return (v1 === v2) ? options.fn(this) : options.inverse(this);
    case '!=':
      return (v1 != v2) ? options.fn(this) : options.inverse(this);
    case '!==':
      return (v1 !== v2) ? options.fn(this) : options.inverse(this);
    case '<':
      return (v1 < v2) ? options.fn(this) : options.inverse(this);
    case '<=':
      return (v1 <= v2) ? options.fn(this) : options.inverse(this);
    case '>':
      return (v1 > v2) ? options.fn(this) : options.inverse(this);
    case '>=':
      return (v1 >= v2) ? options.fn(this) : options.inverse(this);
    case '&&':
      return (v1 && v2) ? options.fn(this) : options.inverse(this);
    case '||':
      return (v1 || v2) ? options.fn(this) : options.inverse(this);
    default:
      return options.inverse(this);
  }
});
//require("@rails/ujs").start()
//global.Rails = Rails;
require('jquery-ujs');
//import "/home/nam/.rvm/gems/ruby-2.4.1@ohs/gems/cocoon-1.2.14/app/assets/javascripts/cocoon"
import * as Framework from 'application/javascripts/jquery_plugin_cus/framework';
import toastr from 'toastr';
import '../application/stylesheets/new_application.scss';

import * as Routes from 'routes.js';
window.Routes = Routes;
window.Framework = Framework;
window.toastr = toastr;

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$(function() {
  $('#toggleSidebar').on('click', () => {
    let jSidebarMenuRow = $('.sidebarMenu');
    if(jSidebarMenuRow.css('display') == 'none') {
      jSidebarMenuRow.css('display', 'block');
    } else {
      jSidebarMenuRow.css('display', 'none');
    }
  })
});

  $(function() {
  var $table = $('#table-five-s-processes')
  var $remove = $('#remove')
    $table.on('check.bs.table uncheck.bs.table check-all.bs.table uncheck-all.bs.table', function () {
      $remove.prop('disabled', !$table.bootstrapTable('getSelections').length)
    })
    $remove.click(function () {
      var ids = $.map($table.bootstrapTable('getSelections'), function (row) {
        return row.id
      })
      let url = Routes.safety_five_s_process_path(ids.join(','));
      $.ajax({
        url: url,
        method: 'DELETE',
        contentType: 'application/json',
        success: function(result) {
          $table.bootstrapTable('remove', {
            field: 'id',
            values: ids
          });
        },
        error: function(request,msg,error) {
          toastr.error(request.responseJSON.error.message);
        }
      });
      $remove.prop('disabled', true)
    })
  })
