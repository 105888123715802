import React from 'react';

const HracNav = (props) => {
  return(
    <div>
      <ol className="subnav">
        <li className="subnav-item">
          <a href="#">Likelihoods</a>
        </li>
        <li className="subnav-item"></li>
        <li className="subnav-item"></li>
        <li className="subnav-item"></li>
      </ol>
    </div>
  )
}

export default HracNav