import React from "react"
import SeverityList from './list'
import { Form, Col, Row, Button, Table, Tabs, Tab } from 'react-bootstrap'
import SeverityForm from "./form"
import { GetSeverity, DeleteSeverity } from '../../api/severities'
import { toast } from 'react-toastify'

const FORM_TAB = "form"
const LIST_TAB = "list"

class Likelihoods extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      activeTab: LIST_TAB,
      severities: [],
      editing: {}
    }
  }
  componentDidMount(){
    this.loadSeverities()
  }
  handleChangeTab = async (tab) => {
    return this.setState({ activeTab: tab})
  }
  loadSeverities = async () => {
    let res = await GetSeverity()
    if(res){
      this.setState({severities: res})
    }
  }
  onSubmitSuccess = async () => {
      this.setState({activeTab: "list"})
      return this.loadSeverities()
  }
  
  onEdit = (item) => {
    this.setState({editing: item, activeTab: FORM_TAB})
  }
  onCancel = () => {
    this.setState({activeTab: "list"})
  }

  onDelete = async (id) => {
    let res = await DeleteSeverity(id)
    toast.success(`Severity #${id} was deleted successfully`)
    this.loadSeverities()
  }
  render(){
    console.log(this.state)
    return(
      <React.Fragment>
      <Row>
        <Col md={12}>
        <Tab.Container>
          <Tabs activeKey={this.state.activeTab} className="custom-tab-content" onSelect={k => this.handleChangeTab(k)}>
            <Tab eventKey={LIST_TAB} title={"List Severities"}>
              <SeverityList onDelete={this.onDelete} onEdit={this.onEdit} severities={this.state.severities} />
            </Tab>
            <Tab eventKey={FORM_TAB} title={"Create Serveriry"}>
              <SeverityForm editItem={this.state.editing} onCancel={this.onCancel} onSuccess={this.onSubmitSuccess} />
            </Tab>
          </Tabs>
        </Tab.Container>
        </Col>
      </Row>
      </React.Fragment>
    )
  }
}

export default Likelihoods