import React from "react"
import HracNav from '../../common/hrac_nav'
import { CreateLikelihoods, UpdateLikelihoods } from '../../api/likelihoods'
import { toast }  from 'react-toastify'
import { Form, Col, Row, Button } from 'react-bootstrap'
class LikelihoodsForm extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      params: { active: true},
      errors: {},
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (nextProps.editItem !== prevState.editItem){
      return { editItem: nextProps.editItem };
   }
   else return null;
 }
 componentDidUpdate(prevProps, prevState) {
  if (prevState.editItem !== this.state.editItem) {
    this.setState({params: this.state.editItem})
  }
}
  onFieldChange = (field, e) => {
    this.setState({params: {...this.state.params, [field]: field == "active" ?  e.checked :  e.value}})
  }

  isValid = (params) => {
    let isValid = true
    let errors = this.state.errors
    if(params.name ==  "" || params.name == undefined){
      isValid = false
      errors["name"] =  "Name can't be blank"
    }
    if(params.description ==  "" || params.name == undefined) {
      isValid = false
      errors["description"] =  "Description can't be blank"
    }
    this.setState({errors})
    return isValid
  }
  
  onSubmit = async () => {
    if(this.isValid(this.state.params)){
      let res 
      if(this.state.params.id){
        res = await UpdateLikelihoods(this.state.params)
      }else{
        res = await CreateLikelihoods(this.state.params)
      }
      if(res){
        toast.success( !!this.state.params.id ?  "Update likelihoods successfully" : "Create likelihoods successfully")
        this.props.onSuccess()
        this.setState({params: {}, editItem: {}, errors: {}})
      }else{
        toast.error("Create likelihoods error, please try again!")
      }
    }
  }
  onCancel = () => {
    this.setState({params: {}, editItem: {}, errors: {}})
    return this.props.onCancel()
  }
  render () {
    console.log(this.state)
    let { params } = this.state
    return (
      <React.Fragment>
        <div className="mpContent">
          <Row>
            <Col xs="12">
              <Row>
                <Col xs="6">
                  <Form>
                    <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control 
                        onChange={(e) => this.onFieldChange("name", e.target)} 
                        type="name" 
                        defaultValue={params.name}
                        placeholder="Enter name"
                        isInvalid={!!this.state.errors["name"]}
                     />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control  
                      defaultValue={params.description}
                      isInvalid={!!this.state.errors["description"]} 
                      onChange={(e) => this.onFieldChange("description", e.target)} 
                      type="text" placeholder="Enter description" />
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check 
                      onChange={(e) => this.onFieldChange("active", e.target)} 
                      type="checkbox" label="Active"
                      defaultChecked={params.active}
                      />
                  </Form.Group>
                  <Button variant="primary" onClick={this.onSubmit}>
                    Submit
                  </Button>
                  <Button variant="default" onClick={this.onCancel}>
                    Cancel
                  </Button>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
          </div>
      </React.Fragment>
    );
  }
}

export default LikelihoodsForm
