import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const Loading = () => {
    return <div>Loading...</div>
}
const loading = () => <Loading />;
const LikelihoodsForm = React.lazy(()=> import('./likelihoods/form'))
const ListLikelihoods = React.lazy(()=> import('./likelihoods/index'))
const Serverity = React.lazy(()=> import('./serverity'))


export default class App extends React.Component{
  render(){
    return(
      <React.Suspense fallback={loading()}>
      <ToastContainer autoClose={3000} hideProgressBar={true} />
      <Router>
        <div className="mpInfoItem app-content">
          <div className="row navigation-page">
            <div className="col-12">
            <ol className="subnav">
              <li className="subnav-item">
                <Link to="/hrac/likelihoods">Likelihoods</Link>
              </li>
              <li className="subnav-item">
                <Link to="/hrac/severities">Serverities</Link>
              </li>
              <li className="subnav-item"></li>
              <li className="subnav-item"></li>
            </ol>
            </div>
          </div>

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
              
          <Switch>
              <Route path="/hrac/likelihoods" exact={true} render={props => <ListLikelihoods {...props}/>}>
                
              </Route>
              <Route path="/hrac/likelihoods/new" exact={true} render={props => <LikelihoodsForm {...props}/>}>
              
              </Route>
              <Route path="/hrac/severities" exact={true} render={props => <Serverity {...props}/>}>
                
              </Route>
          </Switch>
        </div>
      </Router>
      </React.Suspense>
    ) 
  }
}