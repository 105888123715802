import React from 'react';
import { Table, Button } from 'react-bootstrap'
import SweetAlert from 'sweetalert2-react';

class SeverityList extends React.Component {
  constructor(props){
    super(props)
    this.state = {}
  }
  onDeleteClick = (id) => {
    this.setState({deleteItem: id, show: true})
  }
  render(){
    return(
      <React.Fragment>
      <Table className="custom-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Description</th>
            <th>Active</th>
            <th>Created At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.severities.map((item, index) => {
            return <tr className="tr-custom" key={index}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>{item.active}</td>
                <td>{item.created_at}</td>
                <td>
                  <Button onClick={() => this.props.onEdit(item)} variant="primary">Edit</Button>{' '}
                  <Button onClick={() => this.onDeleteClick(item.id)} variant="danger">Delete</Button>{' '}
                </td>
              </tr>
          })}
        </tbody>
      </Table>
      <SweetAlert
        show={this.state.show}
        title="Delete severity"
        text="Are you sure?"
        showCancelButton
        onConfirm={() => {
          this.props.onDelete(this.state.deleteItem);
          this.setState({ show: false });
        }}
        onCancel={() => {
          console.log('cancel');
          this.setState({ show: false });
        }}
        onEscapeKey={() => this.setState({ show: false })}
        onOutsideClick={() => this.setState({ show: false })}
      />
      </React.Fragment>
    )
  }
}

export default SeverityList