/**
 * File generated by js-routes 2.2.3
 * Based on Rails 5.2.4 routes of ReportSafeUp::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /accident_generals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accident_general_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accident_generals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /accident_generals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accident_generals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"accident_generals"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /accident_reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accident_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accident_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /accident_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accident_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"accident_reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /activated(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activated_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activated"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/ajx_company_add_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_company_add_user_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"ajx_company_add_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/ajx_company_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_company_create_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"ajx_company_create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/ajx_company_delete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_company_delete_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"ajx_company_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/ajx_company_edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_company_edit_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"ajx_company_edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/ajx_company_manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_company_manage_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"ajx_company_manage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/ajx_company_remove_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_company_remove_user_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"ajx_company_remove_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/ajx_company_save(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_company_save_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"ajx_company_save"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/ajx_company_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_company_update_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"ajx_company_update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /departments/ajx_department_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_department_create_departments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[6,"ajx_department_create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /departments/ajx_department_delete/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_department_delete_departments_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[6,"ajx_department_delete"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /departments/ajx_department_edit/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_department_edit_departments_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[6,"ajx_department_edit"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /departments/ajx_department_manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_department_manage_departments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[6,"ajx_department_manage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /departments/ajx_department_save(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_department_save_departments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[6,"ajx_department_save"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /departments/ajx_department_update/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_department_update_departments_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[6,"ajx_department_update"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /divisions/ajx_division_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_division_create_divisions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[2,[7,"/"],[2,[6,"ajx_division_create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /divisions/ajx_division_delete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_division_delete_divisions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[2,[7,"/"],[2,[6,"ajx_division_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /divisions/ajx_division_edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_division_edit_divisions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[2,[7,"/"],[2,[6,"ajx_division_edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /divisions/ajx_division_manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_division_manage_divisions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[2,[7,"/"],[2,[6,"ajx_division_manage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /divisions/ajx_division_save(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_division_save_divisions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[2,[7,"/"],[2,[6,"ajx_division_save"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /divisions/ajx_division_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_division_update_divisions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[2,[7,"/"],[2,[6,"ajx_division_update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /human/ajx_human_add_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_human_add_user_human_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"human"],[2,[7,"/"],[2,[6,"ajx_human_add_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /human/ajx_human_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_human_create_human_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"human"],[2,[7,"/"],[2,[6,"ajx_human_create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /human/ajx_human_delete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_human_delete_human_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"human"],[2,[7,"/"],[2,[6,"ajx_human_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /human/ajx_human_edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_human_edit_human_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"human"],[2,[7,"/"],[2,[6,"ajx_human_edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /human/ajx_human_manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_human_manage_human_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"human"],[2,[7,"/"],[2,[6,"ajx_human_manage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /human/ajx_human_remove_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_human_remove_user_human_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"human"],[2,[7,"/"],[2,[6,"ajx_human_remove_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /human/ajx_human_save(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_human_save_human_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"human"],[2,[7,"/"],[2,[6,"ajx_human_save"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /human/ajx_human_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_human_update_human_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"human"],[2,[7,"/"],[2,[6,"ajx_human_update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_permissions/ajx_permission_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_permission_create_user_permissions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_permissions"],[2,[7,"/"],[2,[6,"ajx_permission_create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_permissions/ajx_permission_delete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_permission_delete_user_permissions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_permissions"],[2,[7,"/"],[2,[6,"ajx_permission_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_permissions/ajx_permission_edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_permission_edit_user_permissions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_permissions"],[2,[7,"/"],[2,[6,"ajx_permission_edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_permissions/ajx_permission_manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_permission_manage_user_permissions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_permissions"],[2,[7,"/"],[2,[6,"ajx_permission_manage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_permissions/ajx_permission_save(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_permission_save_user_permissions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_permissions"],[2,[7,"/"],[2,[6,"ajx_permission_save"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_permissions/ajx_permission_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_permission_update_user_permissions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_permissions"],[2,[7,"/"],[2,[6,"ajx_permission_update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report_types/ajx_reportType_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_reportType_create_report_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report_types"],[2,[7,"/"],[2,[6,"ajx_reportType_create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report_types/ajx_reportType_delete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_reportType_delete_report_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report_types"],[2,[7,"/"],[2,[6,"ajx_reportType_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report_types/ajx_reportType_edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_reportType_edit_report_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report_types"],[2,[7,"/"],[2,[6,"ajx_reportType_edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report_types/ajx_reportType_manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_reportType_manage_report_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report_types"],[2,[7,"/"],[2,[6,"ajx_reportType_manage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report_types/ajx_reportType_save(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_reportType_save_report_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report_types"],[2,[7,"/"],[2,[6,"ajx_reportType_save"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report_types/ajx_reportType_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_reportType_update_report_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report_types"],[2,[7,"/"],[2,[6,"ajx_reportType_update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /roles/ajx_role_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_role_create_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"ajx_role_create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /roles/ajx_role_delete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_role_delete_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"ajx_role_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /roles/ajx_role_edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_role_edit_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"ajx_role_edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /roles/ajx_role_manage_permission(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_role_manage_permission_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"ajx_role_manage_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /roles/ajx_role_manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_role_manage_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"ajx_role_manage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /roles/ajx_role_save(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_role_save_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"ajx_role_save"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /roles/ajx_role_update_permission(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_role_update_permission_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"ajx_role_update_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /roles/ajx_role_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_role_update_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"ajx_role_update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ajx_sent_mail_manager(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_sent_mail_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ajx_sent_mail_manager"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ajx_update_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajx_update_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ajx_update_status"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /approvedAccidentReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approvedAccidentReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"approvedAccidentReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /approvedAdminAccidentReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approvedAdminAccidentReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"approvedAdminAccidentReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user_reports/assign_task(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_task_user_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_reports"],[2,[7,"/"],[2,[6,"assign_task"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /classified_reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const classified_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"classified_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /classified_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const classified_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"classified_reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company_user_roles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const company_user_role_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"company_user_roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company_user_roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const company_user_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company_user_roles"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /createAccidentReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createAccidentReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"createAccidentReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /createReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"createReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user_reports/create_assign_task(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_assign_task_user_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_reports"],[2,[7,"/"],[2,[6,"create_assign_task"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s_processes/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_safety_five_s_processes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s_processes"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /departments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const department_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /departments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const departments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"departments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /divisions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const division_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /divisions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const divisions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /draftAccidentReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const draftAccidentReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"draftAccidentReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /draftReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const draftReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"draftReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /accident_generals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_accident_general_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accident_generals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /accident_reports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_accident_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accident_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /classified_reports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_classified_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"classified_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /company_user_roles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_company_user_role_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"company_user_roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /departments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_department_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /divisions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_division_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /human/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_human_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"human"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/positions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_process_five_s_position_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"positions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_report_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"report_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /roles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_role_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s_processes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_safety_five_s_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/five_s_firsts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_safety_observation_five_s_first_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"five_s_firsts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/five_s_seconds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_safety_observation_five_s_second_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"five_s_seconds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/five_s_thirds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_safety_observation_five_s_third_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"five_s_thirds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /setting_models/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_setting_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"setting_models"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /topic_reports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_topic_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"topic_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user_companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user_permissions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_permission_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_permissions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user_reports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user_report_details/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_report_detail_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_report_details"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user_report_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_report_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_report_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /roles/getPermissionOfRole(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const getPermissionOfRole_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"getPermissionOfRole"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/ajx_manage_report(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_ajx_manage_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"ajx_manage_report"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/ajx_save_company_selected(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_ajx_save_company_selected_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"ajx_save_company_selected"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /human/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const human_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"human"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /human(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const human_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"human"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/:id/jstree_load_company(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const jstree_load_company_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"jstree_load_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manageAccidentReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manageAccidentReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manageAccidentReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manageCompany(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manageCompany_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manageCompany"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manageDepartment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manageDepartment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manageDepartment"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manageDivision(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manageDivision_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manageDivision"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manageHuman(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manageHuman_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manageHuman"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /managePermission(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const managePermission_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"managePermission"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manageReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manageReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manageReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manageReportType(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manageReportType_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manageReportType"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manageRole(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manageRole_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manageRole"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manageTemplateReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manageTemplateReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manageTemplateReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manageTopicReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manageTopicReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manageTopicReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /accident_generals/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_accident_general_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"accident_generals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /accident_reports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_accident_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"accident_reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /classified_reports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_classified_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"classified_reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company_user_roles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_company_user_role_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company_user_roles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /departments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_department_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /divisions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_division_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /human/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_human_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"human"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/positions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_process_five_s_position_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"positions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_report_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /roles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_role_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s_processes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_safety_five_s_process_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s_processes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/five_s_firsts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_safety_observation_five_s_first_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"five_s_firsts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/five_s_seconds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_safety_observation_five_s_second_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"five_s_seconds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/five_s_thirds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_safety_observation_five_s_third_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"five_s_thirds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /setting_models/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_setting_model_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"setting_models"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /topic_reports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_topic_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"topic_reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user_permissions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_permission_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_permissions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_reports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_report_details/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_report_detail_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_report_details"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_report_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_report_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_report_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /notification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notification"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /process/five_s/positions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const process_five_s_position_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"positions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/positions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const process_five_s_positions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /refreshDB(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refreshDB_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"refreshDB"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /refreshDBSave(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refreshDBSave_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"refreshDBSave"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /registrations_multi_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const registrations_multi_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"registrations_multi_user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /registrations_multi_user_and_set_permissions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const registrations_multi_user_and_set_permissions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"registrations_multi_user_and_set_permissions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rejectedAccidentReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rejectedAccidentReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rejectedAccidentReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /report_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"report_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report_types"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /roles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const role_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /process/five_s_processes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const safety_five_s_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s_processes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s_processes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const safety_five_s_processes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s_processes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/five_s_firsts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const safety_observation_five_s_first_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"five_s_firsts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/five_s_firsts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const safety_observation_five_s_firsts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"five_s_firsts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/five_s_seconds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const safety_observation_five_s_second_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"five_s_seconds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/five_s_seconds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const safety_observation_five_s_seconds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"five_s_seconds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/five_s_thirds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const safety_observation_five_s_third_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"five_s_thirds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /process/five_s/five_s_thirds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const safety_observation_five_s_thirds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"process"],[2,[7,"/"],[2,[6,"five_s"],[2,[7,"/"],[2,[6,"five_s_thirds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /security(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const security_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"security"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sentAccidentReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sentAccidentReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sentAccidentReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sentReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sentReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sentReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sent_mail_manager(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sent_mail_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sent_mail_manager"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /setting_models/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"setting_models"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /setting_models(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_models_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"setting_models"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /successAccidentReport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const successAccidentReport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"successAccidentReport"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /topic_reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const topic_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"topic_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /topic_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const topic_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"topic_reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user_companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_companies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user_companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_permissions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_permission_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_permissions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_permissions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_permissions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_permissions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user_reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_report_details/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_report_detail_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_report_details"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_report_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_report_details_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_report_details"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user_report_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_report_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_report_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_report_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_report_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_report_templates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /warning(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const warning_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"warning"],[1,[2,[8,"."],[3,"format"]]]]]);


