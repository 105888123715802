import * as Api from '../api'
const LIKELIHOODS_PATH = "/hrac/api/likelihoods"

export const CreateLikelihoods = async (params) => {
  try {
    return await Api.post(LIKELIHOODS_PATH, params)
  } catch(err){
    console.log(err)
    return null
  }
}


export const GetLikelihoods = async () => {
  try {
    return await Api.get(LIKELIHOODS_PATH)
  } catch(err){
    console.log(err)
    return null
  }
}

export const UpdateLikelihoods = async (params) => {
  try {
    return await Api.put(LIKELIHOODS_PATH + "/" + params.id, params)
  } catch(err){
    console.log(err)
    return null
  }
}


export const DeleteLikelihoods = async (id) => {
  try {
    return await Api.deleted(LIKELIHOODS_PATH + "/" + id)
  } catch(err){
    console.log(err)
    return null
  }
}